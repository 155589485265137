/* You can add global styles to this file, and also import other style files */
@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
//@import "~bootstrap/dist/css/bootstrap.css";

//ag grid
ag-grid-angular {
  width: 100% !important;
  height: 100% !important;
}

.ag-theme-alpine {
  .ag-root-wrapper {
    border: none;
    height: 100%;
    min-height: 400px;
  }

  --ag-odd-row-background-color: #F5F5F5;

  .ag-row-selected {
    background-color: transparent !important;
  }

  .ag-row-text-red {
    color: red !important;
  }
}

.ag-theme-alpine .ag-row-odd {
  background-color: var(--ag-odd-row-background-color);
}

.ag-row-deleted-background {
  background-color: #ffe6e6 !important;
}

/* LAYOUT */
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

h1 {
  font-size: 32px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-32 {
  font-size: 32px !important;
}

.font-size-66 {
  font-size: 66px !important;
}

.text-justify {
  text-align: justify !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left !important;
}

.vertical-align-middle {
  vertical-align: middle;
}

.mt-1 {
  margin-top: 10px;
}

.mt-2 {
  margin-top: 20px;
}

.mt-3 {
  margin-top: 30px;
}

.mt-4 {
  margin-top: 40px;
}

.mt-5 {
  margin-top: 50px;
}

.mb-1 {
  margin-bottom: 10px;
}

.mb-2 {
  margin-bottom: 20px;
}

.mb-3 {
  margin-bottom: 30px;
}

.mb-4 {
  margin-bottom: 40px;
}

.mb-5 {
  margin-bottom: 50px;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-1 {
  margin-left: 10px;
}

.ml-2 {
  margin-left: 20px;
}

.ml-3 {
  margin-left: 30px;
}

.ml-4 {
  margin-left: 40px;
}

.ml-5 {
  margin-left: 50px;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-1 {
  margin-right: 10px;
}

.mr-2 {
  margin-right: 20px;
}

.mr-3 {
  margin-right: 30px;
}

.mr-4 {
  margin-right: 40px;
}

.mr-5 {
  margin-right: 50px;
}

.mr-6 {
  margin-right: 60px;
}

.pt-1 {
  padding-top: 10px;
}

.inline-block {
  display: inline-block;
}

.flex-container {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.rounded {
  border-radius: 10px;
}

.full-width {
  width: 100% !important;
}

.full-height {
  width: 100% !important;
}

/* FLEX HELPERS */
.flex {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
}

.flex-two-items {
  width: calc(50% - 10px); //THIS CAUSES THINGS NOT TO STACK
  margin: 5px;
  flex-direction: column;
}

.flex-grow {
  flex-grow: 1;
}


.flex-shrink {
  flex-grow: 0;
}

.flex-end {
  align-content: flex-end;
  align-items: flex-end;
  justify-content: flex-end;
}

.flex-center {
  justify-content: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-full-auto {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-start {
  align-items: flex-start;
}

/* TABLES */
table {
  tbody {
    display: block;
    //height: 50px; SET THIS TO SCROLL
    overflow: auto;
  }

  thead,
  tbody tr {
    display: table;
    width: calc(100% - 1em);
    /* scrollbar is average 1em/16px width, remove it from thead width */
    table-layout: fixed;
    /* even columns width , fix width of table too*/
  }

  thead {
    width: calc(100% - 1em);
    /* scrollbar is average 1em/16px width, remove it from thead width */
  }

  td,
  th {
    //padding: 1px;
    text-align: center;
  }
}

/* FIELDSETS */
fieldset {
  margin-top: 10px;
  border: 1px solid #666;
  border-radius: 8px;
  box-shadow: 0 0 5px #666;

  legend {
    font-weight: bold;
    font-size:18px;
    background: #fff;
  }
}

/* FORMS */
.required:after {
  content:"*";
  color: red
}

.mat-label {
  font-size: 11px !important;
  color: rgba(0, 0, 0, 0.54) !important;
  letter-spacing: 1.5px !important;
  line-height: 15.75px !important;
  font-weight: 400 !important;
  font-family: roboto !important;
}

.form {
  .form-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.form-section {
  margin: 5px 10px 25px 10px;
}

.form-section-header-title {
  font-weight: 500;
  font-size: 28px;
  height: 70px;
  background-color: #242464;
  color: white;
  padding-top: 15px;
  padding-left: 10px;
  text-align: center;
  line-height: 30px;
  border-radius: 8px;
  box-shadow: 0 0 5px #666;
}

.form-section-header {
  font-weight: 500;
  font-size: 22px;
  height: 30px;
}

.form-section-pinned-right {
  display: flex;
  justify-content: flex-end;
  font-weight: 500;
  font-size: 16px;
  height: 24px;
}

.form-section-pinned-right-offset {
  margin-top: -24px;
}

/* MATERIAL */
.mat-form-field {
  width: 180px;
}

.mat-form-field-tiny {
  width: 50px;
}

.mat-form-field-smaller {
  width: 75px;
}

.mat-form-field-small {
  width: 100px;
}

.mat-form-field-date {
  width: 200px;
}

.mat-form-field-double {
  width: 350px !important;
}

.mat-form-field-wide {
  width: 500px !important;
}

.mat-form-field-full {
  width: 100% !important;
  max-width: 500px !important;
}

.mat-form-field-with-settings-button {
  width: 108px !important;
}

.mat-form-field-unset {
  width: unset !important;
}

.mat-button-disabled {
  opacity: .5;
}

.mat-slide-toggle-thumb {
  background-color: #242464 !important;
}

mat-chip {
  cursor: pointer !important;
}

mat-card.dark {
  background-color: #525051;
  color: white;

  input,
  textarea {
    width: 90%;
    background-color: white;
    color: black;
    border-radius: 6px;
    padding: 15px 5% 15px 5%;
  }
}

.mat-form-field-prefix,
.mat-form-field-suffix {
  display: flex;
}

.mat-datepicker-toggle {
  margin-right: 5px;
  margin-bottom: 5px;
}

.mat-form-field-flex {
  align-items: end !important;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  margin-bottom: 3px;
  margin-left: 5px;
}

.mat-tooltip {
  max-width: 400px !important;
  font-size:14px;
  white-space: pre-line !important;
}

button {
  //min-width: 100px !important;
  //background-image: linear-gradient(to bottom, #242464 0%, #242464 10%, #c7332b 100%) !important;
  //color: white !important;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }

  &.submit-button {
    width: 30%;
    height: 45px;
    margin-left: 35%;

    &:hover {
      cursor: pointer;
    }
  }
}

.primary-color {
  color: #242464;
}

a:link {
  color: #0074D9;
}

textarea {
  max-height: 300px !important;
}

//snackbar
.snackbar-custom {
  background-color: white;
  color: black;
  min-width: 250px !important;
  max-width: 350px !important;
  margin-top: 80px !important;
}

.snackbar-custom-success {
  background-color: white;
  color: #28a745;
  min-width: 250px !important;
  max-width: 350px !important;
  margin-top: 80px !important;
}

.snackbar-custom-error {
  background-color: white;
  color: #dc3545;
  min-width: 250px !important;
  max-width: 350px !important;
  margin-top: 80px !important;
}

//custom


.error {
  color: #dc3545;
}

.bold {
  font-weight: bold;
}

.subtle-text {
  font-size: smaller;
  color: gray;
  font-style: italic;
}

.italics {
  font-style: italic;
}

.smaller {
  font-size: smaller;
}

.larger {
  font-size: 1.15em;
}

.float-left {
  float: left;
}

.float-right {
  float: right
}

.clear-both {
  clear: both;
}

.text-area-display {
  white-space: pre-wrap;
  text-align: left;
}

.ellipsis-50 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50px;
}

.ellipsis-100 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

.ellipsis-200 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.ellipsis-300 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}
